import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { Person } from "ngx-uaa/lib/models/person";
import { HttpProfileService } from "ngx-uaa-services";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, delay, map, retryWhen, startWith, take } from "rxjs/operators";
import { Role } from '../../models/role.enum';


enum UserStatus {
  idle = 'idle',
  loaded = 'loaded',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private currentUserBS: BehaviorSubject<Person> = new BehaviorSubject<Person>(null);
  readonly currentUser$: Observable<{ user: Person; status: UserStatus }>;

  readonly BACKOFFICE_ROLES = ['ROLE_ROOT', 'ROLE_ADMIN', 'ROLE_OPERATORE', 'ROLE_DOWNLOAD'];

  constructor(
    private http: HttpClient,
    private profileService: HttpProfileService
  ) {
    this.currentUser$ = this.currentUserBS.pipe(
      map(user => {
        return {
          user,
          status: user ? UserStatus.loaded : UserStatus.idle,
        };
      }),
      startWith({ user: null, status: UserStatus.loaded }),
    );
  }

  Init() {
    return new Promise<Person | void>((resolve, reject) => {
      this.profileService.current(true).subscribe(
        next => {
          this.currentUserBS.next(next);
          resolve();
        }, error => {
          resolve();
        });
    });
  }

  getCurrentUserBS() {
    return this.currentUserBS;
  }

  isAdmin() {
    const current = this.currentUserBS.getValue();
    return !!(current && current.identity.roles.includes('ROLE_ROOT'));
  }

  isRoles(roles: Role | Role[]) {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    const user = this.currentUserBS.getValue();
    return user && user.identity && user.identity.roles && user.identity.roles.some((role) => roles.includes(role));
  }

  hasBackofficeRole() {

    let hasRole = true;
    const current = this.currentUserBS.getValue();
    if (!current) {
      hasRole = false;
    } else {
      const roles: any[] = this.currentUserBS.getValue().identity.roles;

      if (!roles) {
        hasRole = false;
      } else {
        hasRole = this.BACKOFFICE_ROLES.some(role => roles.includes(role));
      }
    }

    // console.log(hasRole);
    return hasRole;
  }

  create(req) {
    const url = "/api/sign-up";
    return this.http.post(url, req);
  }

  resendOTP() {
    const url = '/uaa/api/otp-resend';
    return this.http.post(url, {})
      .pipe(
        retryWhen(errors => errors.pipe(delay(2000), take(2))),
        catchError(() => of([]))
      );
  }

  verifyOTP(noRobotCode: string, token: string, fiscalCode: string) {
    const url = '/uaa/api/otp-verify';
    return this.http.post(url, { noRobotCode, token, username: fiscalCode });
  }

  /*
      async getCurrentUser(): Promise<Person> {
          return this.currentUser;
      }

      getUser() {
          let url = '/uaa/api/profile';
          return this.http.get(url);
      }

      async getUtente(): Promise<Person> {
          return this.profileService.current(true).toPromise();
      }

      public hasRoles(roles: string[]) {
          let res = false;
          roles.forEach(perm => {
              res = res || this.hasRole(perm);
          });
          return res;
      }

      private hasRole(role: string) {
          return this.currentUser.identity.roles.indexOf(role) > -1;
      }

      async login(loginRequest: ILoginRequest): Promise<Person> {
          return this.httpAuthService.login(loginRequest)
              .toPromise()
              .then((res) => this.setToken(res))
              .then(() => this.getUtente())
              .then((res) => {
                  return this.currentUser = res;
              });
      }

      async logout() {
          return this.httpAuthService.logout(true)
              .toPromise()
              .then(() => this.currentUser = null);
      }

      create(req) {

          const url = "/api/sign-up";

          return this.http.post(url, req);
      }

      private setToken(res: any) {
          return this.httpAuthService.setAccessToken(res.access_token);
      }

      public getToken() {
          return jwt_decode(this.httpAuthService.getAccessToken());
      }*/

}
