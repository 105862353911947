import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ProfiloComponent} from "./modules/shared/components/profile/profilo.component";
import {LayoutComponent} from "./modules/shared/components/layout/layout/layout.component";
import {AdminAuthGuardService} from "./modules/admin-area/services/admin-auth-guard.service";
import {UaaAuthGuardService} from "./modules/admin-area/services/uaa-auth-guard.service";

const routes: Routes = [
    {
        path: 'public',
        loadChildren: () => import('../app/modules/public-area/public-area.module')
            .then(m => m.PublicAreaModule),
    },
    {
        path: 'reservation',
        loadChildren: () => import('./modules/reservation-area/reservation-area.module')
            .then(m => m.ReservationAreaModule),
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'delivery-center',
        loadChildren: () => import('./modules/delivery-center-area/delivery-center-area.module')
            .then(m => m.DeliveryCenterAreaModule),
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'service-queue',
        loadChildren: () => import('../app/modules/service-queue-area/service-queue-area.module')
            .then(m => m.ServiceQueueAreaModule),
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'admin',
        loadChildren: () => import('../app/modules/admin-area/admin-area.module')
            .then(m => m.AdminAreaModule),
        canActivate: [UaaAuthGuardService]
    },
    {
        path: 'auth',
        loadChildren: () => import('../app/modules/login-area/login-area.module')
            .then(m => m.LoginAreaModule),
    },
    {
        path: 'intro',
        loadChildren: () => import('./modules/intro-area/intro-area.module')
            .then(m => m.IntroAreaModule),
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'profile',
        component: LayoutComponent,
        children: [{
            path: '',
            component: ProfiloComponent,
        }]
    },
    {
        path: 'login',
        redirectTo: '/auth/login',
    },
    {
        path: 'index',
        redirectTo: '/public',
    },
    {
        path: '',
        redirectTo: '/public',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/public',
        pathMatch: 'full'
    }
    /*
    {
        path: '**',
        component: NotFoundComponent,
    },
    */
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
