import { Component, Inject } from '@angular/core';
import { BASE_HREF } from "../../../../base-href";

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
        <nb-layout>
            <nb-layout-header fixed>
                <ngx-header [userMenu]="userMenuAdmin"></ngx-header>
            </nb-layout-header>

            <nb-sidebar *ngIf="authenticated" class="menu-sidebar" tag="menu-sidebar" responsive start>
                <ng-content select="nb-menu"></ng-content>
            </nb-sidebar>

            <nb-layout-column>
                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>

            <nb-layout-footer fixed>
                <ngx-footer></ngx-footer>
            </nb-layout-footer>
        </nb-layout>
    `,
})
export class OneColumnLayoutComponent {

  authenticated = true;
  baseHref: string;

  userMenuAdmin = [];

  constructor(
    @Inject(BASE_HREF) baseHref
  ) {
    this.baseHref = baseHref;
    this.userMenuAdmin = [
      { title: 'Profilo', link: '/profile' },
      { title: 'Esci', link: '/auth/logout' },
    ];
    /*this.authService.getCurrentUser().then((res) => {
        if (res !== undefined) {
            this.authenticated = true;
        }
    });*/
  }
}
