import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { StepperFormService } from "../../../shared/services/stepper-form.service";
import { FormGroup } from "@angular/forms";
import { LocalStorageService } from "ngx-store";
import { PRENO_LOCAL_STORAGE_KEYS, STORAGE_KEYS } from '../../../shared/constants';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  barState = new Subject();

  interval;
  form: FormGroup;

  max = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.reservation)
    ? JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.reservation)).expireIn
    : 0;
  timer: number;
  progress;


  constructor(
    private formService: StepperFormService,
    private localStorageService: LocalStorageService
  ) {
    this.form = this.formService.getForm();
    this.timer = moment(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate)).diff(moment(), 'seconds');
    this.progress = (100 * this.timer) / this.max;
  }

  setBar(expireIn: number, timer?: number) {
    if (!this.interval && (timer || this.max == 0)) {
      if (!this.max) {
        this.max = expireIn;
      }
      timer ? this.timer = timer : this.timer = this.max;
      this.startTimer();
    }
  }

  extendTime(ext) {
    this.max += ext;
    this.timer += ext;
  }

  startTimer() {
    const doThings = () => {
      this.timer >= 0 ? this.timer-- : 0;
      this.form.controls['expireIn'].setValue(this.timer);
      if (this.timer <= 0) {
        this.stopTimer();
      }

      this.progress = (100 * this.timer) / this.max;
      this.barState.next({ progress: this.progress, timer: this.timer });

      if (this.timer <= 0) {
        localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate);
        localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.reservation);
        localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.timer);
        localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.startedTimer);
      }
    }

    this.interval = setInterval(() => {
      doThings();
    }, 1000);
  }

  stopTimer() {
    this.max = 0;

    this.form.controls['expireIn'].reset();
    this.form.controls['reservationId'].reset();

    if (this.localStorageService.get('stepperData')) {
      this.localStorageService.remove('stepperData');
    }

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = undefined;
  }

  getBarState() {
    return this.barState.asObservable();
  }

}
