import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
import * as i5 from "../../../modules/shared/services/text-change.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "d-block w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "span", [["class", "created-by"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "| "])), i1.ɵpod(3, { year: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "link"], ["routerLink", "/public/privacy-policy"], ["target", "_blank"]], [[1, "aria-label", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Privacy policy"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "link"], ["routerLink", "/public/cookie-policy"], ["target", "_blank"]], [[1, "aria-label", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Cookie policy"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "a", [["class", "link"], ["routerLink", "/public/terms-conditions"], ["target", "_blank"]], [[1, "aria-label", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Termini e condizioni"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["class", "link"], ["routerLink", "/public/legal-notes"], ["target", "_blank"]], [[1, "aria-label", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Note legali"]))], function (_ck, _v) { var currVal_4 = "_blank"; var currVal_5 = "/public/privacy-policy"; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_9 = "_blank"; var currVal_10 = "/public/cookie-policy"; _ck(_v, 9, 0, currVal_9, currVal_10); var currVal_14 = "_blank"; var currVal_15 = "/public/terms-conditions"; _ck(_v, 13, 0, currVal_14, currVal_15); var currVal_19 = "_blank"; var currVal_20 = "/public/legal-notes"; _ck(_v, 17, 0, currVal_19, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changeTextService.changeText("copyright", _ck(_v, 3, 0, ("" + _co.year))); _ck(_v, 2, 0, currVal_0); var currVal_1 = "privacy policy"; var currVal_2 = i1.ɵnov(_v, 5).target; var currVal_3 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = "cookie policy"; var currVal_7 = i1.ɵnov(_v, 9).target; var currVal_8 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8); var currVal_11 = "termini e condizioni"; var currVal_12 = i1.ɵnov(_v, 13).target; var currVal_13 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_11, currVal_12, currVal_13); var currVal_16 = "note legali"; var currVal_17 = i1.ɵnov(_v, 17).target; var currVal_18 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_16, currVal_17, currVal_18); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i4.FooterComponent, [i5.TextChangeService], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("ngx-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
