export const textThemes = {
  defaultTheme: {
    copyright: 'Smart Hospital S.r.l. {year}',
  },
  'green-rlTheme': {
    copyright:
      '@ Copyright {year} Regione Lombardia tutti i diritti riservati - 80050050154 - Piazza Città di Lombardia 1 - 20124 Milano ',
  },
  dromedianTheme: {
    copyright:
      '@ Copyright {year} Dromedian tutti i diritti riservati - 02147390690 -  Via Po 43 - 66020 - SAN GIOVANNI TEATINO (CH) ',
  },
  prodTheme: {
    copyright: 'Smart Hospital S.r.l. {year}',
  },
  stagingTheme: {
    copyright: 'Smart Hospital S.r.l. {year}',
  },
};
