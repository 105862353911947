import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http/auth.service";
import * as i2 from "@angular/router";
export class AdminAuthGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        const hasRole = this.authService.hasBackofficeRole();
        if (!hasRole) {
            this.router.navigate(['/public']);
        }
        return hasRole;
    }
}
AdminAuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminAuthGuardService_Factory() { return new AdminAuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AdminAuthGuardService, providedIn: "root" });
