import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { AuthStrategy, AvailableAuthStrategy } from './types';

@Injectable({
  providedIn: 'root',
})
export class AuthStrategiesService {
  private readonly IS_DEV = false;

  readonly availableStrategies: {
    fetch: () => Observable<AvailableAuthStrategy[]>;
    data: () => AvailableAuthStrategy[];
    status: () => 'idle' | 'loading' | 'loaded' | 'error';
  };

  get providers(): AvailableAuthStrategy[] {
    return this.availableStrategies
      .data()
      .filter((strategy) => strategy.type !== AuthStrategy.Local);
  }

  constructor(private httpClient: HttpClient) {
    this.availableStrategies = this.buildAvailableStrategiesQuery();
  }

  private buildAvailableStrategiesQuery() {
    let data: AvailableAuthStrategy[] = [];
    let status: 'idle' | 'loading' | 'loaded' | 'error' = 'idle';

    if (this.IS_DEV) {
      return {
        fetch: () =>
          of([
            {
              type: AuthStrategy.SPID,
              path: null,
              title: null,
            },
            {
              type: AuthStrategy.CIE_DISABLED,
              path: null,
              title: 'CIE',
            },
          ]).pipe(
            tap((strategies) => {
              data = strategies;
              status = 'loaded';
            }),
            catchError((error) => {
              status = 'error';
              console.error(error);

              return of([]);
            })
          ),
        data: () => data,
        status: () => status,
      };
    }

    return {
      fetch: () => this.httpClient.get<AvailableAuthStrategy[]>('/api/config/auth-method').pipe(
        tap(strategies => {
          data = strategies;
          status = 'loaded';
        }),
        catchError(error => {
          status = 'error';
          console.error(error);

          return of([]);
        }),
      ),
      data: () => data,
      status: () => status,
    };
  }

}
