<!--        <div id="nb-global-spinner" class="spinner">-->
<!--            <div class="blob blob-0"></div>-->
<!--            <div class="blob blob-1"></div>-->
<!--            <div class="blob blob-2"></div>-->
<!--            <div class="blob blob-3"></div>-->
<!--            <div class="blob blob-4"></div>-->
<!--            <div class="blob blob-5"></div>-->
<!--        </div>-->
<ngx-notify-notifications
  [i18nJsonPath]="deployUrl + 'assets/i18n'"
  [options]="{
    showProgressBar: true,
    timeOut: 7500,
    position: ['bottom', 'center'],
    animate: 'fromBottom',
    theClass: 'notificationbox'
  }"
>
</ngx-notify-notifications>

<nb-layout>
  <nb-layout-column class="p-0">
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
