export const RBAC = {
  download: 'download',
  manageService: 'manageService',
  manageDistrict: 'manageDistrict',
  manageServiceDistrict: 'manageServiceDistrict',
  manageConfiguration: 'manageConfiguration',
  manageReservation: 'manageReservation',
}

type ValueOf<T> = T[keyof T];
export type RbacKey = keyof typeof RBAC;
export type Rbac = ValueOf<typeof RBAC>;
