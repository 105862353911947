import {Component} from '@angular/core';
import {TextChangeService} from "../../../modules/shared/services/text-change.service";

@Component({
    selector: 'ngx-footer',
    styleUrls: ['./footer.component.scss'],
    template: `
        <div class="d-block w-100">
            <span class="created-by">{{this.changeTextService.changeText('copyright', {year : ''+year})}}|
                <a class="link" [attr.aria-label]="'privacy policy'" routerLink="/public/privacy-policy" target="_blank">Privacy policy</a> |
                <a class="link" [attr.aria-label]="'cookie policy'" routerLink="/public/cookie-policy" target="_blank">Cookie policy</a> |
                <a class="link" [attr.aria-label]="'termini e condizioni'" routerLink="/public/terms-conditions" target="_blank">Termini e condizioni</a> |
                <a class="link" [attr.aria-label]="'note legali'" routerLink="/public/legal-notes" target="_blank">Note legali</a>
            </span>
        </div>
    `,
})

export class FooterComponent {
  constructor(public changeTextService : TextChangeService) {
  }
    year = new Date().getFullYear();
}
