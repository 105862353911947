import { BehaviorSubject } from 'rxjs';
import { PRENO_LOCAL_STORAGE_KEYS, STORAGE_KEYS } from '../../../shared/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/stepper-form.service";
import * as i2 from "ngx-store";
import * as i3 from "../../../shared/services/http/auth.service";
import * as i4 from "./progress-bar.service";
export var FlowClass;
(function (FlowClass) {
    FlowClass["Default"] = "DEFAULT";
    FlowClass["ThirdPartReservation"] = "THIRD_PART_RESERVATION";
    FlowClass["DailyCode"] = "DAILY_CODE";
})(FlowClass || (FlowClass = {}));
export class StepperService {
    constructor(formService, localStorageService, authService, progressBarService) {
        this.formService = formService;
        this.localStorageService = localStorageService;
        this.authService = authService;
        this.progressBarService = progressBarService;
        this.selectedIndex = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex)
            ? parseInt(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex))
            : 0;
        this.stepsPreno = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.steps)
            ? JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.steps))
            : [];
        this.steps = new BehaviorSubject([]);
        this.steps$ = this.steps.asObservable();
    }
    goTo() {
        this.manageSteps();
        let form = this.formService.getForm();
        let targetStep = '';
        if (form.controls['loggedIn'].value) {
            if (form.controls['phoneVerified'].value) {
                targetStep = 'confirm';
            }
            else {
                targetStep = 'otp';
            }
        }
        else {
            this.next();
            return;
        }
        this.selectedIndex = this.stepsPreno.find(step => step.name === targetStep).selectedIndex;
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
    }
    goToAuth() {
        if (this.localStorageService.get('stepperData')) {
            const stepperData = this.localStorageService.get('stepperData');
            const isLoggedIn = stepperData.loggedIn;
            const phoneVerified = stepperData.phoneVerified;
            if (!isLoggedIn && !phoneVerified) {
                this.selectedIndex = this.stepsPreno.find(step => step.name === 'auth').selectedIndex;
                localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
            }
        }
    }
    goBack(stepper) {
        this.manageSteps();
        stepper.selectedIndex = this.stepsPreno.find(step => step.name === 'time').selectedIndex;
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, stepper.selectedIndex);
    }
    previous() {
        this.manageSteps();
        this.selectedIndex--;
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
        if (this.selectedIndex === 0) {
            for (const key of Object.values(STORAGE_KEYS.local)) {
                localStorage.removeItem(key);
            }
            for (const key of Object.values(STORAGE_KEYS.session)) {
                sessionStorage.removeItem(key);
            }
        }
    }
    next() {
        this.manageSteps();
        this.selectedIndex++;
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
    }
    manageSteps() {
        const form = this.formService.getForm();
        const isLoggedIn = form.get('roles') && form.get('roles').value;
        this.stepsPreno = [];
        const pushStep = (name, selectedIndex) => {
            this.stepsPreno.push({ name, selectedIndex: selectedIndex || this.stepsPreno.length });
        };
        pushStep('servizio');
        if (form.get('longDescription').value) {
            pushStep('longDescription');
        }
        if (isLoggedIn && form.get('flowType').value === FlowClass.ThirdPartReservation) {
            const userRoles = form.get('roles').value;
            const admittedRoles = form.get('thirdPartyAdmittedRoles').value;
            const canBookFor = admittedRoles.includes('*') || userRoles.some(role => admittedRoles.includes(role));
            if (canBookFor) {
                pushStep('modePrenotazione');
            }
        }
        if (form.get('flowType').value === FlowClass.DailyCode) {
            pushStep('dailyCode');
        }
        pushStep('presidio');
        pushStep('data');
        pushStep('time');
        if (!form.get('loggedIn').value) {
            pushStep('auth');
        }
        if (!form.get('phoneVerified').value) {
            pushStep('otp');
        }
        pushStep('confirm');
        pushStep('result');
        this.steps.next(this.stepsPreno);
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.steps, JSON.stringify(this.stepsPreno));
    }
    reset() {
        this.selectedIndex = 0;
        this.progressBarService.stopTimer();
        if (this.selectedIndex === 0) {
            for (const key of Object.values(STORAGE_KEYS.local)) {
                localStorage.removeItem(key);
            }
            for (const key of Object.values(STORAGE_KEYS.session)) {
                sessionStorage.removeItem(key);
            }
        }
    }
}
StepperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StepperService_Factory() { return new StepperService(i0.ɵɵinject(i1.StepperFormService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.AuthService), i0.ɵɵinject(i4.ProgressBarService)); }, token: StepperService, providedIn: "root" });
