import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "../../shared/services/http/auth.service";

@Injectable({
    providedIn: 'root'
})
export class UaaAuthGuardService implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isAdmin = this.authService.isAdmin();
        if (!isAdmin) {
            this.router.navigate(['/reservation/list']);
        }
        return isAdmin;
    }
}
