import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthStrategy } from './types';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthStrategiesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.IS_DEV = false;
        this.availableStrategies = this.buildAvailableStrategiesQuery();
    }
    get providers() {
        return this.availableStrategies
            .data()
            .filter((strategy) => strategy.type !== AuthStrategy.Local);
    }
    buildAvailableStrategiesQuery() {
        let data = [];
        let status = 'idle';
        if (this.IS_DEV) {
            return {
                fetch: () => of([
                    {
                        type: AuthStrategy.SPID,
                        path: null,
                        title: null,
                    },
                    {
                        type: AuthStrategy.CIE_DISABLED,
                        path: null,
                        title: 'CIE',
                    },
                ]).pipe(tap((strategies) => {
                    data = strategies;
                    status = 'loaded';
                }), catchError((error) => {
                    status = 'error';
                    console.error(error);
                    return of([]);
                })),
                data: () => data,
                status: () => status,
            };
        }
        return {
            fetch: () => this.httpClient.get('/api/config/auth-method').pipe(tap(strategies => {
                data = strategies;
                status = 'loaded';
            }), catchError(error => {
                status = 'error';
                console.error(error);
                return of([]);
            })),
            data: () => data,
            status: () => status,
        };
    }
}
AuthStrategiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthStrategiesService_Factory() { return new AuthStrategiesService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthStrategiesService, providedIn: "root" });
