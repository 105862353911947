import { Component, Inject, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { BASE_HREF } from '../../../../../../base-href';
import { LeftMenu } from '../../../constants/leftMenu';
import { ROLE } from '../../../models/role.enum';
import { AuthService } from '../../../services/http/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  menu: NbMenuItem[] = [];
  baseHref: string = '';

  constructor(
    @Inject(BASE_HREF) baseHref,
    private authService: AuthService
  ) {
    this.baseHref = baseHref;
    LeftMenu.forEach(m => {
      if (m.url && !m.url.includes(this.baseHref)) {
        let temp = m.url;
        m.url = this.baseHref + temp;
      }
      if (m.title === 'Amministrazione') {
        m.hidden = !this.authService.isAdmin();
      }
      if (m.title === 'Presidi') {
        m.hidden = this.authService.isRoles(ROLE.Download);
      }
    });
    this.menu = LeftMenu;
  }

  ngOnInit() {
  }

}
