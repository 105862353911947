const NGX_PREFIX = 'ngx_';
const PRENO_PREFIX = 'preno_';

export const PRENO_LOCAL_STORAGE_KEYS = Object.freeze({
  form: `${PRENO_PREFIX}form`,
  timer: `${PRENO_PREFIX}timer`,
  startedTimer: `${PRENO_PREFIX}startedTimer`,
  selectedIndex: `${PRENO_PREFIX}selectedIndex`,
  reservation: `${PRENO_PREFIX}reservation`,
  steps: `${PRENO_PREFIX}steps`,
  availableDays: `${PRENO_PREFIX}availableDays`,
  expirationDate: `${PRENO_PREFIX}expirationDate`,
  otpControl: `${NGX_PREFIX}otpControl`,
})
