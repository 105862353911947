import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbAlertModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbThemeModule
} from '@nebular/theme';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RecaptchaModule, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { EasymdeModule } from 'ngx-easymde';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { NgxNotifyLibModule } from 'ngx-notify';
import { WebStorageModule } from 'ngx-store';
import { IT_i18n, PASSWORD_PATTERN } from 'ngx-uaa';
import {
  HttpAuthService,
  HttpPersonService,
  HttpProfileService,
  NgxUaaServicesLibModule
} from 'ngx-uaa-services';
import { BASE_HREF } from '../base-href';
import { DEPLOY_URL } from '../deploy-url';
import { environment } from '../environments/environment';
import { CoreModule } from './@core/core.module';
import { PipesModule } from './@core/models-[VIETATO-AGGIUNGERE-ALTRO]/pipes/pipes.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpAuthInterceptor } from './interceptors/http-auth-interceptor';
import { HttpXsrfInterceptor } from './interceptors/http-csrf.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpI18nInterceptor } from './interceptors/http-i18n-interceptor';
import { HttpResetInterceptor } from './interceptors/http-reset.interceptor';
import { BaseModule } from './modules/login-area/components/base/base.module';
import {
  AuthStrategiesService,
  AvailableAuthStrategy
} from './modules/shared/features/auth-strategies';
import { AuthService } from './modules/shared/services/http/auth.service';
import { RealmSettingService } from './modules/shared/services/http/realm-setting.service';
import { SharedModule } from './modules/shared/shared.module';
import { StepperService } from './modules/stepper/default-stepper/services/stepper.service';
import { NbMomentDateModule } from '@nebular/moment';
import {TextChangeService} from "./modules/shared/services/text-change.service";


registerLocaleData(localeIt, 'it-IT');
/*
    ^                         Start anchor
    (?=.*[A-Z])               Almeno una lettera maiuscola.
    (?=.*[a-z])               Almeno una lettera minuscola.
    .{8}                      Almeno 8 caratteri.
    $                         End anchor.
 */
const password_pattern = {
  re: '^(?=.*[a-z])(?=.*[A-Z]).{8,}$',
  flag: '',
};

export function initializeAuthService(authService: AuthService) {
  return (): Promise<any> => {
    return authService.Init();
  };
}
export function initializeConfigs(realmSettingService: RealmSettingService) {
  return (): Promise<any> => {
    return realmSettingService.setConfigs();
  };
}

export function initAuthProviders(
  authProvidersService: AuthStrategiesService
): () => Promise<AvailableAuthStrategy[]> {
  /**
   * TODO
   * Remove .toPromise() when Angular will be at least v12
   */
  return () => authProvidersService.availableStrategies.fetch().toPromise();
}


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: DEPLOY_URL.toString(), // or 'your.domain.com'
  },
  palette: {
    popup: {
      background: '#5487b1',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  type: 'info',
  content: {
    message: 'Questo sito utilizza cookie tecnici, analytics e di terze parti.\n' +
      'Proseguendo nella navigazione accetti l’utilizzo dei cookie.',
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: '/page/public/cookie-policy',
    dismiss: 'Acconsento',
  },
  elements: {
    messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link"
      href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
      </span>`,
  },
};

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href: string, title: string, text: string) => {
    const titlePart = title ? `title="${title}"` : '';

    return `<a href="${href}" ${titlePart} target="_blank">${text}</a>`;
  };

  return {
    renderer,
  };
}

@NgModule({

  declarations: [
    AppComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BaseModule,
    NgxNotifyLibModule,
    SharedModule,
    NgxUaaServicesLibModule,
    // WebStorageModule,

    // togliere le dipendenze

    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    BsDropdownModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMomentDateModule,
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),

    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot(),

    PipesModule,
    NbLayoutModule,
    NbCardModule,
    NbAlertModule,
    NbCheckboxModule,
    FormsModule,
    NbInputModule,
    // NbAuthModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NbIconModule,
    WebStorageModule,

    NbThemeModule.forRoot({
      name: 'default'
    }),

    EasymdeModule.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
  ],

  providers: [
    HttpProfileService,
    HttpAuthService,
    HttpPersonService,
    StepperService,
    AuthService,
    // AdminAuthGuardService,

    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpI18nInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.uaaRecaptchaKey },
    { provide: APP_INITIALIZER, deps: [AuthService], useFactory: initializeAuthService, multi: true },

    { provide: APP_INITIALIZER, deps: [RealmSettingService], useFactory: initializeConfigs, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuthProviders,
      deps: [AuthStrategiesService],
      multi: true,
    },

    {
      provide: PASSWORD_PATTERN, useValue: {
        pattern: new RegExp(password_pattern.re, password_pattern.flag),
        helper: 'La password deve contenere almeno una lettera maiuscola, una minuscola e una lunghezza di almeno 8 caratteri.',
      },
    },
  ],

  bootstrap: [AppComponent],
  exports: [
    AppComponent,
    NgxNotifyLibModule,
  ],
})

export class AppModule {
  constructor(
    translate: TranslateService,
    @Inject(DEPLOY_URL) public deployUrl,
    @Inject(BASE_HREF) public baseHref,
  ) {
    translate.setTranslation('it', IT_i18n, true);

  }
}

/*

 BaseModule,

    NgxNotifyLibModule,
    NgxJsonFormModule,
    LoadingBarHttpClientModule,

    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot(),
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),

    BsDropdownModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),

    BsDatepickerModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),

    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),

    PipesModule,
    NbLayoutModule,
    NbCardModule,
    NbAlertModule,
    NbCheckboxModule,
    FormsModule,
    NbInputModule,
    NbAuthModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NbIconModule

 */
