import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from "../modules/shared/services/http/auth.service";


@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log(event);

                    /*                    // Dopo la login lancio la get profile
                                        if (event.url.endsWith('/api/login')) {
                                            this.currentUserService.Init().then();
                                            this.currentUserService.setJWT(event.body);
                                        }
                    */

                    // Dopo la logout lo metto a null il valore del BS
                    if (event.url.endsWith('/api/logout')) {
                        this.authService.getCurrentUserBS().next(null);
                    }

                    // Dopo la profile aggiorno sempre il BS
                    if (event.url.endsWith('/api/profile')) {
                        this.authService.getCurrentUserBS().next(event.body);
                    }

                    // In caso di 401 metto a null il valore del BS
                    if (event.status === 401 || event.status === 403) {
                        this.authService.getCurrentUserBS().next(null);
                    }
                    //
                    // if (event.url.endsWith('')){
                    //
                    // }

                    // if (event.status === 200 && event.url.endsWith('/sign-up')) {
                    //     this.toastService.success('Inserimento dati avvenuto con successo', 'Registrazione Completata');
                    // }

                }
                return event;
            })
        );
    }
}
