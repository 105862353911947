import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@nebular/theme";
import * as i3 from "../text-change.service";
export class RealmSettingService {
    constructor(http, themeService, textChangeService) {
        this.http = http;
        this.themeService = themeService;
        this.textChangeService = textChangeService;
        this.realmConfigs = new BehaviorSubject(null);
        this.extendedRealmConfigs = new BehaviorSubject(null);
    }
    setConfigs() {
        return Promise.all([this.setRealmConfigs(), this.setExtendedRealmConfigs()]).then(result => this.textChangeService.loadTexts());
    }
    setRealmConfigs() {
        return new Promise((resolve, reject) => {
            this.http.get("/api/config").subscribe(next => {
                this.realmConfigs.next(next);
                resolve();
            }, error => {
                resolve();
            });
        });
    }
    getRealmConfigs() {
        return this.realmConfigs.asObservable();
    }
    setExtendedRealmConfigs() {
        return new Promise((resolve, reject) => {
            this.http.get("/api/config/extra").subscribe(next => {
                this.extendedRealmConfigs.next(next);
                if (next && next.theme) {
                    this.themeService.changeTheme(next.theme);
                }
                resolve();
            }, error => {
                resolve();
            });
        });
    }
    getExtendedRealmConfigs() {
        return this.extendedRealmConfigs.asObservable();
    }
}
RealmSettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RealmSettingService_Factory() { return new RealmSettingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NbThemeService), i0.ɵɵinject(i3.TextChangeService)); }, token: RealmSettingService, providedIn: "root" });
