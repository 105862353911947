import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbOverlayModule
} from '@nebular/theme';
import { NbCustomOptionGroupComponent } from './option-group.component';
import { NbCustomOptionComponent } from './option.component';
import { NbCustomSelectComponent, NbSelectLabelComponent } from './select.component';
import { NbA11yModule } from './cdk/a11y/a11y.module';


const NB_SELECT_COMPONENTS = [
  NbCustomSelectComponent,
  NbCustomOptionComponent,
  NbCustomOptionGroupComponent,
  NbSelectLabelComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbOverlayModule,
    NbA11yModule.forRoot(),
    NbButtonModule,
    NbInputModule,
    NbCardModule,
    NbCheckboxModule,
    NbIconModule,
  ],
  exports: [...NB_SELECT_COMPONENTS],
  declarations: [...NB_SELECT_COMPONENTS],
})
export class NbCustomSelectModule {
}
