import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../../shared/services/http/auth.service';
import { PRENO_LOCAL_STORAGE_KEYS, STORAGE_KEYS } from '../../../shared/constants';
import { StepperFormService } from '../../../shared/services/stepper-form.service';
import { filter, take, tap } from 'rxjs/operators';
import { ProgressBarService } from './progress-bar.service';
import { Role } from 'src/app/modules/shared/models/role.enum';

export enum FlowClass {
  Default = 'DEFAULT',
  ThirdPartReservation = 'THIRD_PART_RESERVATION',
  DailyCode = 'DAILY_CODE',
}


@Injectable({
  providedIn: 'root'
})
export class StepperService {

  selectedIndex = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex)
    ? parseInt(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex))
    : 0;

  constructor(
    private formService: StepperFormService,
    private localStorageService: LocalStorageService,
    private readonly authService: AuthService,
    private readonly progressBarService: ProgressBarService,
  ) {}

  stepsPreno: { name: string, selectedIndex: number }[] = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.steps)
    ? JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.steps))
    : [];

  private readonly steps: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  readonly steps$: Observable<any> = this.steps.asObservable();

  goTo() {
    this.manageSteps();
    let form = this.formService.getForm();

    let targetStep = '';

    if (form.controls['loggedIn'].value) {
      if (form.controls['phoneVerified'].value) {
        targetStep = 'confirm';
      } else {
        targetStep = 'otp';
      }
    } else {
      this.next();
      return;
    }

    this.selectedIndex = this.stepsPreno.find(step => step.name === targetStep).selectedIndex;
    localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
  }

  goToAuth() {
    if (this.localStorageService.get('stepperData')) {
      const stepperData = this.localStorageService.get('stepperData');

      const isLoggedIn = stepperData.loggedIn;
      const phoneVerified = stepperData.phoneVerified;
      if (!isLoggedIn && !phoneVerified) {
        this.selectedIndex = this.stepsPreno.find(step => step.name === 'auth').selectedIndex;
        localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
      }
    }
  }

  goBack(stepper) {
    this.manageSteps();

    stepper.selectedIndex = this.stepsPreno.find(step => step.name === 'time').selectedIndex;
    localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, stepper.selectedIndex);
  }

  previous() {
    this.manageSteps();
    this.selectedIndex--;
    localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());

    if (this.selectedIndex === 0) {
      for (const key of Object.values(STORAGE_KEYS.local)) {
        localStorage.removeItem(key);
      }

      for (const key of Object.values(STORAGE_KEYS.session)) {
        sessionStorage.removeItem(key);
      }
    }
  }

  next() {
    this.manageSteps();
    this.selectedIndex++;
    localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.selectedIndex, this.selectedIndex.toString());
  }

  manageSteps() {
    const form = this.formService.getForm();
    const isLoggedIn = form.get('roles') && form.get('roles').value;
    this.stepsPreno = [];

    const pushStep = (name: string, selectedIndex?: number): void => {
      this.stepsPreno.push({ name, selectedIndex: selectedIndex || this.stepsPreno.length });
    };

    pushStep('servizio');

    if (form.get('longDescription').value) {
      pushStep('longDescription');
    }

    if (isLoggedIn && form.get('flowType').value === FlowClass.ThirdPartReservation) {
      const userRoles = form.get('roles').value as Array<Role>;
      const admittedRoles = form.get('thirdPartyAdmittedRoles').value as Array<string>;
      const canBookFor = admittedRoles.includes('*') || userRoles.some(role => admittedRoles.includes(role));

      if (canBookFor) {
        pushStep('modePrenotazione');
      }
    }

    if (form.get('flowType').value === FlowClass.DailyCode) {
      pushStep('dailyCode');
    }

    pushStep('presidio');
    pushStep('data');
    pushStep('time');

    if (!form.get('loggedIn').value) {
      pushStep('auth');
    }

    if (!form.get('phoneVerified').value) {
      pushStep('otp');
    }

    pushStep('confirm');
    pushStep('result');

    this.steps.next(this.stepsPreno);
    localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.steps, JSON.stringify(this.stepsPreno));
  }

  reset() {
    this.selectedIndex = 0;
    this.progressBarService.stopTimer();

    if (this.selectedIndex === 0) {
      for (const key of Object.values(STORAGE_KEYS.local)) {
        localStorage.removeItem(key);
      }

      for (const key of Object.values(STORAGE_KEYS.session)) {
        sessionStorage.removeItem(key);
      }
    }
  }

}
