import { NGX_SESSION_STORAGE_KEYS } from "./ngx-session-storage-keys.const";
import { PRENO_LOCAL_STORAGE_KEYS } from "./preno-local-storage-keys.const";

export const STORAGE_KEYS = Object.freeze({
  local: [
    ...Object.values(PRENO_LOCAL_STORAGE_KEYS),
  ],
  session: [
    ...Object.values(NGX_SESSION_STORAGE_KEYS),
  ],
});
