import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss'],
})

export class ProfiloComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
