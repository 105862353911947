import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BehaviorSubject, of } from "rxjs";
import { catchError, delay, map, retryWhen, startWith, take } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-uaa-services";
var UserStatus;
(function (UserStatus) {
    UserStatus["idle"] = "idle";
    UserStatus["loaded"] = "loaded";
})(UserStatus || (UserStatus = {}));
export class AuthService {
    constructor(http, profileService) {
        this.http = http;
        this.profileService = profileService;
        this.currentUserBS = new BehaviorSubject(null);
        this.BACKOFFICE_ROLES = ['ROLE_ROOT', 'ROLE_ADMIN', 'ROLE_OPERATORE', 'ROLE_DOWNLOAD'];
        this.currentUser$ = this.currentUserBS.pipe(map(user => {
            return {
                user,
                status: user ? UserStatus.loaded : UserStatus.idle,
            };
        }), startWith({ user: null, status: UserStatus.loaded }));
    }
    Init() {
        return new Promise((resolve, reject) => {
            this.profileService.current(true).subscribe(next => {
                this.currentUserBS.next(next);
                resolve();
            }, error => {
                resolve();
            });
        });
    }
    getCurrentUserBS() {
        return this.currentUserBS;
    }
    isAdmin() {
        const current = this.currentUserBS.getValue();
        return !!(current && current.identity.roles.includes('ROLE_ROOT'));
    }
    isRoles(roles) {
        if (!Array.isArray(roles)) {
            roles = [roles];
        }
        const user = this.currentUserBS.getValue();
        return user && user.identity && user.identity.roles && user.identity.roles.some((role) => roles.includes(role));
    }
    hasBackofficeRole() {
        let hasRole = true;
        const current = this.currentUserBS.getValue();
        if (!current) {
            hasRole = false;
        }
        else {
            const roles = this.currentUserBS.getValue().identity.roles;
            if (!roles) {
                hasRole = false;
            }
            else {
                hasRole = this.BACKOFFICE_ROLES.some(role => roles.includes(role));
            }
        }
        return hasRole;
    }
    create(req) {
        const url = "/api/sign-up";
        return this.http.post(url, req);
    }
    resendOTP() {
        const url = '/uaa/api/otp-resend';
        return this.http.post(url, {})
            .pipe(retryWhen(errors => errors.pipe(delay(2000), take(2))), catchError(() => of([])));
    }
    verifyOTP(noRobotCode, token, fiscalCode) {
        const url = '/uaa/api/otp-verify';
        return this.http.post(url, { noRobotCode, token, username: fiscalCode });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpProfileService)); }, token: AuthService, providedIn: "root" });
