import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BASE_HREF } from "../base-href";
import { DEPLOY_URL } from "../deploy-url";
import { PRENO_LOCAL_STORAGE_KEYS, STORAGE_KEYS } from './modules/shared/constants';
import { TextChangeService } from "./modules/shared/services/text-change.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ngx-app',
  styles: [],
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {

  private readonly destroyed = new Subject<void>();

  constructor(
    private http: HttpClient,
    @Inject(DEPLOY_URL) public deployUrl,
    @Inject(BASE_HREF) public baseHref,
    private ccService: NgcCookieConsentService, private translateService: TranslateService,
    private readonly router: Router,
    private titleService: Title
  ) {
    // if (sessionStorage.getItem('theme')) {
    //   this.themeService.changeTheme(sessionStorage.getItem('theme'));
    // }

    const cookiePolicyUrl = baseHref + 'public/cookie-policy';
    document.getElementById('cookieconsent:desc').getElementsByClassName('cc-link')[0].removeAttribute('href');
    document.getElementById('cookieconsent:desc').getElementsByClassName('cc-link')[0].setAttribute('href', cookiePolicyUrl);
  }

  ngOnInit() {
    this.router.events.pipe(
      takeUntil(this.destroyed),
      filter(event => event instanceof NavigationEnd),
      filter(({ url }: NavigationEnd) => url === '/auth/logout'),
      tap(() => {
        for (const key of Object.values(STORAGE_KEYS.local)) {
          localStorage.removeItem(key);
        }

        for (const key of Object.values(STORAGE_KEYS.session)) {
          sessionStorage.removeItem(key);
        }
      }),
    ).subscribe();

    moment.locale('it');
    // Support for translated cookies messages
    this.translateService.addLangs(['it']);
    this.translateService.setDefaultLang('it');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/it/) ? browserLang : 'it');

    this.translateService
      .get(['cookie.allow', 'cookie.deny'])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {};
        // Override default messages with the translated ones
        this.ccService.getConfig().content.allow = "Acconsento";// data['cookie.allow'];
        this.ccService.getConfig().content.deny = "";// data['cookie.deny'];
        this.ccService.destroy();//remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });

    const expirationDate = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate)
      ? moment(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate)).isValid()
        ? moment(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate)).toISOString()
        : null
      : null;

    if (
      !expirationDate
      || (expirationDate && moment().isAfter(moment(expirationDate)))
    ) {
      for (const key of Object.values(STORAGE_KEYS.local)) {
        localStorage.removeItem(key);
      }

      for (const key of Object.values(STORAGE_KEYS.session)) {
        sessionStorage.removeItem(key);
      }
    }
    this.redirect()
  }

  redirect(): void {
    const redirectUrl = localStorage.getItem('spid-redirect-here')
    localStorage.removeItem('spid-redirect-here')
    if (!redirectUrl) {
      return
    }
    // if the redirect url points to /uaa/* or an external resource, leave the page
    if (redirectUrl.startsWith('/uaa/') || redirectUrl.startsWith('http')) {
      window.location.href = redirectUrl
    } else {
      this.router.navigateByUrl(redirectUrl, { replaceUrl: true })
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
