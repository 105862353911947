import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Rbac, RBAC } from '../modules/shared/constants/rbac';
import { ACTIONS_BY_ROLE } from '../modules/shared/directives/show-if-allowed/actions-by-role.token';
import { Role, ROLE } from '../modules/shared/models/role.enum';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { MockDataModule } from './service-[VIETATO-AGGIUNGERE-ALTRO]/mock-data.module';
import { AnalyticsService } from './utils';


export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  AnalyticsService,
];

export const ACTIONS_MAP = {
  [ROLE.Root]: [
    RBAC.download,
    RBAC.manageService,
    RBAC.manageDistrict,
    RBAC.manageServiceDistrict,
    RBAC.manageConfiguration,
    RBAC.manageReservation,
  ],
  [ROLE.Operator]: [
    RBAC.manageReservation,
  ],
  [ROLE.Admin]: [
    RBAC.download,
    RBAC.manageService,
    RBAC.manageDistrict,
    RBAC.manageServiceDistrict,
    RBAC.manageConfiguration,
    RBAC.manageReservation,
  ],
  [ROLE.Download]: [RBAC.download],
  [ROLE.Scheduler]: [
    RBAC.manageConfiguration,
    RBAC.manageDistrict,
    RBAC.manageService,
    RBAC.manageServiceDistrict,
  ]
} as Record<Role, Rbac[]>;

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
        {
          provide: ACTIONS_BY_ROLE,
          useValue: ACTIONS_MAP
        }
      ],
    };
  }
}
