import { Routes } from '@angular/router';
import { ProfiloComponent } from "./modules/shared/components/profile/profilo.component";
import { LayoutComponent } from "./modules/shared/components/layout/layout/layout.component";
import { AdminAuthGuardService } from "./modules/admin-area/services/admin-auth-guard.service";
import { UaaAuthGuardService } from "./modules/admin-area/services/uaa-auth-guard.service";
const ɵ0 = () => import("./modules/public-area/public-area.module.ngfactory")
    .then(m => m.PublicAreaModuleNgFactory), ɵ1 = () => import("./modules/reservation-area/reservation-area.module.ngfactory")
    .then(m => m.ReservationAreaModuleNgFactory), ɵ2 = () => import("./modules/delivery-center-area/delivery-center-area.module.ngfactory")
    .then(m => m.DeliveryCenterAreaModuleNgFactory), ɵ3 = () => import("./modules/service-queue-area/service-queue-area.module.ngfactory")
    .then(m => m.ServiceQueueAreaModuleNgFactory), ɵ4 = () => import("./modules/admin-area/admin-area.module.ngfactory")
    .then(m => m.AdminAreaModuleNgFactory), ɵ5 = () => import("./modules/login-area/login-area.module.ngfactory")
    .then(m => m.LoginAreaModuleNgFactory), ɵ6 = () => import("./modules/intro-area/intro-area.module.ngfactory")
    .then(m => m.IntroAreaModuleNgFactory);
const routes = [
    {
        path: 'public',
        loadChildren: ɵ0,
    },
    {
        path: 'reservation',
        loadChildren: ɵ1,
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'delivery-center',
        loadChildren: ɵ2,
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'service-queue',
        loadChildren: ɵ3,
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'admin',
        loadChildren: ɵ4,
        canActivate: [UaaAuthGuardService]
    },
    {
        path: 'auth',
        loadChildren: ɵ5,
    },
    {
        path: 'intro',
        loadChildren: ɵ6,
        canActivate: [AdminAuthGuardService]
    },
    {
        path: 'profile',
        component: LayoutComponent,
        children: [{
                path: '',
                component: ProfiloComponent,
            }]
    },
    {
        path: 'login',
        redirectTo: '/auth/login',
    },
    {
        path: 'index',
        redirectTo: '/public',
    },
    {
        path: '',
        redirectTo: '/public',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/public',
        pathMatch: 'full'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
