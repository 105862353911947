import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { DEPLOY_URL } from './deploy-url';
import { BASE_HREF } from "./base-href";

if (environment.production) {
  enableProdMode();
}

//see https://stackoverflow.com/questions/47885451/angular-cli-build-using-base-href-and-deploy-url-to-access-assets-on-cdn
const deployUrl = (function () {
  const scripts = document.getElementsByTagName('script');
  const index = scripts.length - 1;
  const mainScript = scripts[index];
  return mainScript.src.replace(/main.*?\.js$/, '');
})();

const DEPLOY_URL_PROVIDER = {
  provide: DEPLOY_URL,
  useValue: deployUrl
};

const baseHref = (function () {
  const base = document.getElementsByTagName('base');
  return base[0].href.replace(deployUrl, '')
})();

const BASE_HREF_PROVIDER = {
  provide: BASE_HREF,
  useValue: baseHref
};

platformBrowserDynamic([DEPLOY_URL_PROVIDER, BASE_HREF_PROVIDER])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
