import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DEPLOY_URL} from "../../../../deploy-url";
import {NbThemeService} from "@nebular/theme";
import {textThemes} from "../../../variablesText";

@Injectable({
  providedIn: 'root'
})
export class TextChangeService {
  private currentTheme: string = 'default';
  private textChanges: any;
  private deployUrl: string;
  constructor(private http: HttpClient,
              @Inject(DEPLOY_URL) deployUrl: string,
              private themeService: NbThemeService) {
    this.deployUrl = deployUrl;
  }
  Init() {

  }
  public loadTexts() {
    let currentTheme = this.themeService.currentTheme ?
      this.themeService.currentTheme : 'default';
    this.textChanges = textThemes[currentTheme+'Theme'];
  }
  public changeText(key: string, variables?: Record<string, string>): string {
    let change = this.textChanges[key] || '';
    if (variables) {
      Object.keys(variables).forEach(variableKey => {
        change = change.replace(`{${variableKey}}`, variables[variableKey]);
      });
    }
    return change;
  }

}
