import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable} from "rxjs/Observable";
import {map} from "rxjs/operators";

@Injectable()
export class HttpI18nInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request instanceof HttpRequest) {
            if (request.url.indexOf("/assets/i18n/") > 0) {
                const res = request.clone({
                    withCredentials: false
                });
                return next.handle(res);
            }
        }
        return next.handle(request);
    }

}
