import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../modules/shared/services/http/auth.service';
import { DEPLOY_URL } from "../../../../deploy-url";
import { Person } from "ngx-uaa/lib/models/person";
import { Router } from "@angular/router";
import { HttpAuthService } from "ngx-uaa";
import { RealmSettingService } from "../../../modules/shared/services/http/realm-setting.service";
import { BASE_HREF } from "../../../../base-href";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() hideMenu;

  @Input() showUserNav;

  @Input() userMenu = [];

  themeSub: Subscription;
  currentUSerSub: Subscription;

  public deployUrl: string;
  public baseHref: string = '';

  private destroy$: Subject<void> = new Subject<void>();

  userPictureOnly: boolean = true;

  user: Person;

  currentTheme = 'default';

  //menu
  prenoMenu = [];
  notLoggedMenu = [];
  smallDevsMenu = [];
  userMenuAdmin;
  //menu

  // default value
  logoUrl: string;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private httpAuthService: HttpAuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private nbMenuService: NbMenuService,
    private customThemeService: RealmSettingService,
    @Inject(DEPLOY_URL) deployUrl: string,
    @Inject(BASE_HREF) baseHref: string,
  ) {
    this.deployUrl = deployUrl;
    this.baseHref = baseHref;

    this.logoUrl = this.deployUrl + "assets/images/START-BOOKING-BLU.svg";
    this.themeSub = this.customThemeService
      .getRealmConfigs()
      .subscribe(res => {
        if (res && res.logoUrl) {
          this.logoUrl = res.logoUrl;
        }
      });
    document.documentElement.style.setProperty("--logo-url", "url('" + this.logoUrl + "')");

    this.notLoggedMenu = [
      { title: 'Accedi', link: '/auth/login' },
    ];
    this.smallDevsMenu = [
      { title: 'Prenota', link: '/public' },
      { title: 'Le mie prenotazioni', link: '/public/list' },
    ];
    this.userMenuAdmin = { title: 'Area riservata', link: '/reservation/list' };
    this.prenoMenu = [
      { title: 'Le mie prenotazioni', link: '/public/list' },
    ];
  }

  ngOnInit() {
    this.themeService.onThemeChange().subscribe((theme)=>{
     this.currentTheme = theme.name;
    })
    this.currentUSerSub = this.authService.getCurrentUserBS().subscribe(next => {
      if (!next) {
        // sessionStorage.removeItem('stepper-form');
        // sessionStorage.removeItem('selectedIndex');
        // sessionStorage.removeItem('steps');
      }

      this.user = next;
      let hasAdminLink = false;
      this.smallDevsMenu.forEach(value => {
        if (value.title === 'Area riservata') {
          hasAdminLink = true;
        }
      });
      if (this.authService.hasBackofficeRole() && !hasAdminLink) {
        this.smallDevsMenu.splice(1, 0, this.userMenuAdmin);
      }

      this.changeDetectorRef.detectChanges();
    });

    this.smallDevsMenu = ([...this.prenoMenu, ...this.userMenu]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.changeDetectorRef.detach();
    this.themeSub.unsubscribe();
    this.currentUSerSub.unsubscribe();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

}
