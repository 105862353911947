import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PRENO_LOCAL_STORAGE_KEYS } from '../constants';
import { ReservationType } from '../models/reservation-type.enum';
import { taxCodeValidator } from '../validators/taxCodeValidator';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./http/auth.service";
export class StepperFormService {
    constructor(fb, authService) {
        this.fb = fb;
        this.authService = authService;
        this.formTeardown = new Subject();
        this.destroyed = new Subject();
    }
    get additionalInfoControl() {
        return this.getForm().get('additionalInfo');
    }
    ngOnDestroy() {
        this.destroyed.next();
        sessionStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.form);
    }
    getForm() {
        if (this.form) {
            return this.form;
        }
        else {
            return this.initForm();
        }
    }
    resetForm() {
        this.form.reset();
    }
    initForm() {
        this.form = this.fb.group({
            serviceQueueId: new FormControl('', []),
            longDescription: new FormControl('', []),
            flowType: new FormControl('DEFAULT', []),
            thirdPartyAdmittedRoles: new FormControl([], []),
            customFields: new FormControl(),
            loggedIn: new FormControl(Boolean(this.authService.getCurrentUserBS().value)),
            roles: new FormControl(),
            phoneVerified: new FormControl(false),
            serviceDistrictQueueId: new FormControl(),
            firstDateAvailable: new FormControl(),
            dateChosen: new FormControl(),
            time: new FormControl(),
            reservationId: new FormControl(),
            editing: new FormControl(false, []),
            oldReservationId: new FormControl(),
            expireIn: new FormControl(),
            reservation: new FormControl(),
            additionalInfo: new FormGroup({
                fiscal_code: new FormControl(''),
                phone_number: new FormControl(),
                email: new FormControl(),
                reservation_type: new FormControl(),
                notes: new FormControl(),
                consent_accepted_on: new FormControl(moment().format('YYYY-MM-DDTHH:mm:ss')),
            }),
        });
        if (localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)) {
            this.form.patchValue(JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)), { emitEvent: false });
        }
        if (this.authService.getCurrentUserBS().value) {
            this.form.controls['roles'].setValue(this.authService.getCurrentUserBS().value.identity.roles);
        }
        this.setReservationType();
        this.form.valueChanges
            .pipe(takeUntil(this.formTeardown), tap((value) => {
            localStorage.setItem(PRENO_LOCAL_STORAGE_KEYS.form, JSON.stringify(value));
        }))
            .subscribe();
        return this.form;
    }
    setReservationType() {
        this.getForm().addControl('additionalInfo', this.fb.group({
            reservation_type: this.fb.control(this.getInitialReservationTypeBasedOnRole()),
        }));
        const isLoggedIn = this.getForm().get('roles') && this.getForm().get('roles').value;
        if (isLoggedIn) {
            const isOperator = this.getForm()
                .get('roles')
                .value.includes('ROLE_OPERATORE');
            if (isOperator) {
                this.additionalInfoControl.addControl('fiscal_code', this.fb.control(JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)) &&
                    JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form))
                        .additionalInfo.fiscal_code, [Validators.required, taxCodeValidator]));
                this.additionalInfoControl.addControl('phone_number', this.fb.control(JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)) &&
                    JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form))
                        .additionalInfo.phone_number, Validators.required));
                this.additionalInfoControl.addControl('email', this.fb.control(JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)) &&
                    JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form))
                        .additionalInfo.email, Validators.required));
                this.additionalInfoControl.addControl('notes', this.fb.control(JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form)) &&
                    JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.form))
                        .additionalInfo.notes, Validators.required));
            }
        }
    }
    getInitialReservationTypeBasedOnRole() {
        if (this.form.get('flowType').value !== 'THIRD_PART_RESERVATION') {
            const loggedUserRoles = this.getForm().get('roles').value;
            if (loggedUserRoles && loggedUserRoles.length) {
                const admittedRoles = this.form.get('thirdPartyAdmittedRoles').value || [];
                const canBook = admittedRoles.includes('*') ||
                    loggedUserRoles.some((role) => admittedRoles.includes(role));
                if (canBook) {
                    return ReservationType.ThirdPartReservation;
                }
            }
        }
        return ReservationType.SelfReservation;
    }
}
StepperFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StepperFormService_Factory() { return new StepperFormService(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthService)); }, token: StepperFormService, providedIn: "root" });
