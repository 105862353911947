import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/http/auth.service";
import * as i2 from "@angular/router";
export class UaaAuthGuardService {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        const isAdmin = this.authService.isAdmin();
        if (!isAdmin) {
            this.router.navigate(['/reservation/list']);
        }
        return isAdmin;
    }
}
UaaAuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UaaAuthGuardService_Factory() { return new UaaAuthGuardService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: UaaAuthGuardService, providedIn: "root" });
