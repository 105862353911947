export enum AuthStrategy {
  Local = 'LOCAL',
  IAM = 'IAM',
  SPID = 'SPID',
  CIE = 'CIE',
  CIE_DISABLED = 'CIE_DISABLED'
}

export interface AvailableAuthStrategy {
  type: AuthStrategy;
  path: string;
  title: string;
}
