export const environment = {
  production: true,
  uaaBaseUrl: '',
  smartBookingBaseUrl: 'booking',
  useHash: true,
  uaaRecaptchaKey: '6LebeF0aAAAAADKbOwmlvQAEbTaDM6xGQll8P8uG',
  uaaLogoUrl: './assets/img/logo.png',
  uaaBackgroundUrl: './assets/img/background.jpeg',
  uaaLoginCustomLinks: []

};
