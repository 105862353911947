import {NgModule} from '@angular/core';
import {NbCardModule, NbIconModule, NbInputModule, NbTreeGridModule} from '@nebular/theme';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {ThemeModule} from '../../../../@theme/theme.module';
import {NgxUaaLibModule} from 'ngx-uaa';

// import { FsIconComponent } from './tree-grid/tree-grid.component';

@NgModule({
    imports: [
        NbCardModule,
        NbTreeGridModule,
        NbIconModule,
        NbInputModule,
        ThemeModule,

        Ng2SmartTableModule,
        NgxUaaLibModule,
    ],
    declarations: [
        // FsIconComponent,
    ],
})

export class BaseModule {}

