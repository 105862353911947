import { textThemes } from "../../../variablesText";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../deploy-url";
import * as i3 from "@nebular/theme";
export class TextChangeService {
    constructor(http, deployUrl, themeService) {
        this.http = http;
        this.themeService = themeService;
        this.currentTheme = 'default';
        this.deployUrl = deployUrl;
    }
    Init() {
    }
    loadTexts() {
        let currentTheme = this.themeService.currentTheme ?
            this.themeService.currentTheme : 'default';
        this.textChanges = textThemes[currentTheme + 'Theme'];
    }
    changeText(key, variables) {
        let change = this.textChanges[key] || '';
        if (variables) {
            Object.keys(variables).forEach(variableKey => {
                change = change.replace(`{${variableKey}}`, variables[variableKey]);
            });
        }
        return change;
    }
}
TextChangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TextChangeService_Factory() { return new TextChangeService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DEPLOY_URL), i0.ɵɵinject(i3.NbThemeService)); }, token: TextChangeService, providedIn: "root" });
