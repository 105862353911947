export let LeftMenu = [
    {
        title: 'Prenotazioni',
        icon: 'calendar',
        link: '/reservation/list',
    }, {
        title: 'Servizi',
        icon: 'layers',
        link: '/service-queue/services',
    },
    {
        title: 'Presidi',
        icon: 'npm',
        link: '/delivery-center',
    },
    {
        title: 'Amministrazione',
        icon: 'people',
        children: [
            {
                title: 'Persone',
                link: '/admin/people',
            },
            {
                title: 'Distretti',
                link: '/admin/district',
            },
            {
                title: 'Ruoli',
                link: '/admin/roles',
            },
            {
                title: 'Gruppi',
                link: '/admin/groups',
            },
        ],
        hidden: false
    },
    {
        title: 'Area pubblica',
        icon: 'external-link-outline',
        url: '/public',
    },
    {
        title: 'Utenti',
        icon: 'person-add',
        link: '/add-users',
        hidden: true
    }
];
