import * as i0 from "./profilo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-uaa/ngx-uaa.ngfactory";
import * as i3 from "ngx-uaa";
import * as i4 from "ngx-notify";
import * as i5 from "./profilo.component";
var styles_ProfiloComponent = [i0.styles];
var RenderType_ProfiloComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfiloComponent, data: {} });
export { RenderType_ProfiloComponent as RenderType_ProfiloComponent };
export function View_ProfiloComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-ngx-uaa-profile-detail", [], null, null, null, i2.View_ProfileDetailComponent_0, i2.RenderType_ProfileDetailComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileDetailComponent, [i3.HttpProfileService, i4.NgxNotificationsService, [2, i3.PASSWORD_PATTERN], [2, i3.UAA_SETTINGS]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ProfiloComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-profilo", [], null, null, null, View_ProfiloComponent_0, RenderType_ProfiloComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProfiloComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfiloComponentNgFactory = i1.ɵccf("ngx-profilo", i5.ProfiloComponent, View_ProfiloComponent_Host_0, {}, {}, []);
export { ProfiloComponentNgFactory as ProfiloComponentNgFactory };
