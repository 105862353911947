import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterJsonPipe } from './pipes/filter-json.pipe';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { NgxUaaLibModule } from "ngx-uaa";
import { ProfiloComponent } from "./components/profile/profilo.component";
import { NgxNotifyLibModule } from "ngx-notify";
import {
  NbButtonModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
} from "@nebular/theme";
import { RouterModule } from "@angular/router";
import { ThemeModule } from "../../@theme/theme.module";
import { NgxUaaServicesLibModule } from "ngx-uaa-services";
import { DynamicBreadcrumbComponent } from "./dynamic-breadcrumb/dynamic-breadcrumb.component";
import { NgxJsonFormModule } from "ngx-json-form";
import { AdvancedSearchFilterComponent } from './components/advanced-search-filter/advanced-search-filter.component';
import { SharedSignUpComponent } from './components/sign-up/shared-sign-up.component';
import { ReactiveFormsModule } from "@angular/forms";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ShowIfHasRoleDirective } from './directives/show-if-has-role.directive';
import { HideWhenHasOnlyDownloadRoleDirective } from './directives/hide-when-has-only-download-role.directive';
import { AuthStrategyDirective } from './features/auth-strategies';
import { ShowIfAllowedDirective } from './directives/show-if-allowed/show-if-allowed.directive';
import { TranslateFromHostnamePipe } from './pipes/hostname.pipe';


const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    FilterJsonPipe,
    TruncatePipe,
    LayoutComponent,
    ProfiloComponent,
    DynamicBreadcrumbComponent,
    AdvancedSearchFilterComponent,
    SharedSignUpComponent,
    ShowIfHasRoleDirective,
    ShowIfAllowedDirective,
    HideWhenHasOnlyDownloadRoleDirective,
    AuthStrategyDirective,
    TranslateFromHostnamePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxNotifyLibModule,
    NgxUaaLibModule,
    NgxUaaServicesLibModule,
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    ThemeModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    NbMenuModule,
    NbIconModule,
    ThemeModule,

    NgxJsonFormModule,
    ReactiveFormsModule,
    NbInputModule,
    NbCheckboxModule,
    NbButtonModule,
    NbSpinnerModule,
    NgxIntlTelInputModule
  ],
  exports: [
    FilterJsonPipe,
    DynamicBreadcrumbComponent,
    SharedSignUpComponent,
    NgxNotifyLibModule,
    TruncatePipe,
    ShowIfHasRoleDirective,
    ShowIfAllowedDirective,
    HideWhenHasOnlyDownloadRoleDirective,
    AuthStrategyDirective,
    TranslateFromHostnamePipe,
  ]
})
export class SharedModule {}
