import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';


@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cookie = this.tokenExtractor.getToken() as string;

    if (cookie && cookie !== '') {
      request.headers.set("X-XSRF-TOKEN", cookie);
    }

    return next.handle(request);
  }

}
