import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {IExtendedRealmSettings, IRealmSetting} from "../../interfaces/IRealmSetting";
import {NbThemeService} from '@nebular/theme';
import {TextChangeService} from "../text-change.service";

@Injectable({
  providedIn: 'root'
})
export class RealmSettingService {

  realmConfigs: BehaviorSubject<IRealmSetting> = new BehaviorSubject<IRealmSetting>(null);
  extendedRealmConfigs: BehaviorSubject<IExtendedRealmSettings> = new BehaviorSubject<IExtendedRealmSettings>(null);

  constructor(
    private http: HttpClient,
    private readonly themeService: NbThemeService,
    private textChangeService : TextChangeService
  ) {
  }

  setConfigs(): Promise<any> {
    return Promise.all([this.setRealmConfigs(), this.setExtendedRealmConfigs()]).then( result =>
      this.textChangeService.loadTexts()
    );
  }

  setRealmConfigs() {
    return new Promise<IRealmSetting>((resolve, reject) => {
      this.http.get<any>("/api/config").subscribe(
        next => {
          this.realmConfigs.next(next);
          resolve();
        }, error => {
          resolve();
        });
    });
  }

  getRealmConfigs() {
    return this.realmConfigs.asObservable();
  }

  setExtendedRealmConfigs() {
    return new Promise<IRealmSetting>((resolve, reject) => {
      this.http.get<any>("/api/config/extra").subscribe(
        next => {
          this.extendedRealmConfigs.next(next);

          if (next && next.theme) {
            this.themeService.changeTheme(next.theme);
          }

          resolve();
        }, error => {
          resolve();
        });
    });
  }

  getExtendedRealmConfigs() {
    return this.extendedRealmConfigs.asObservable();
  }
}
