import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { IT_i18n } from 'ngx-uaa';
import { DEPLOY_URL } from '../deploy-url';
import { environment } from '../environments/environment';
import { AuthStrategiesService } from './modules/shared/features/auth-strategies';
import { AuthService } from './modules/shared/services/http/auth.service';
import { RealmSettingService } from './modules/shared/services/http/realm-setting.service';
registerLocaleData(localeIt, 'it-IT');
const password_pattern = {
    re: '^(?=.*[a-z])(?=.*[A-Z]).{8,}$',
    flag: '',
};
export function initializeAuthService(authService) {
    return () => {
        return authService.Init();
    };
}
export function initializeConfigs(realmSettingService) {
    return () => {
        return realmSettingService.setConfigs();
    };
}
export function initAuthProviders(authProvidersService) {
    return () => authProvidersService.availableStrategies.fetch().toPromise();
}
const cookieConfig = {
    cookie: {
        domain: DEPLOY_URL.toString(),
    },
    palette: {
        popup: {
            background: '#5487b1',
        },
        button: {
            background: '#f1d600',
        },
    },
    theme: 'edgeless',
    type: 'info',
    content: {
        message: 'Questo sito utilizza cookie tecnici, analytics e di terze parti.\n' +
            'Proseguendo nella navigazione accetti l’utilizzo dei cookie.',
        cookiePolicyLink: 'Cookie Policy',
        cookiePolicyHref: '/page/public/cookie-policy',
        dismiss: 'Acconsento',
    },
    elements: {
        messagelink: `
      <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="learn more about cookies" tabindex="0" class="cc-link"
      href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>
      </span>`,
    },
};
export function markedOptionsFactory() {
    const renderer = new MarkedRenderer();
    renderer.link = (href, title, text) => {
        const titlePart = title ? `title="${title}"` : '';
        return `<a href="${href}" ${titlePart} target="_blank">${text}</a>`;
    };
    return {
        renderer,
    };
}
const ɵ0 = environment.uaaRecaptchaKey, ɵ1 = {
    pattern: new RegExp(password_pattern.re, password_pattern.flag),
    helper: 'La password deve contenere almeno una lettera maiuscola, una minuscola e una lunghezza di almeno 8 caratteri.',
};
export class AppModule {
    constructor(translate, deployUrl, baseHref) {
        this.deployUrl = deployUrl;
        this.baseHref = baseHref;
        translate.setTranslation('it', IT_i18n, true);
    }
}
export { ɵ0, ɵ1 };
