import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { DEPLOY_URL } from './deploy-url';
import { BASE_HREF } from "./base-href";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
const ɵ0 = function () {
    const scripts = document.getElementsByTagName('script');
    const index = scripts.length - 1;
    const mainScript = scripts[index];
    return mainScript.src.replace(/main.*?\.js$/, '');
};
const deployUrl = (ɵ0)();
const ɵ1 = deployUrl;
const DEPLOY_URL_PROVIDER = {
    provide: DEPLOY_URL,
    useValue: ɵ1
};
const ɵ2 = function () {
    const base = document.getElementsByTagName('base');
    return base[0].href.replace(deployUrl, '');
};
const baseHref = (ɵ2)();
const ɵ3 = baseHref;
const BASE_HREF_PROVIDER = {
    provide: BASE_HREF,
    useValue: ɵ3
};
__NgCli_bootstrap_2.platformBrowser([DEPLOY_URL_PROVIDER, BASE_HREF_PROVIDER])
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.error(err));
export { ɵ0, ɵ1, ɵ2, ɵ3 };
