import { Subject } from "rxjs";
import { PRENO_LOCAL_STORAGE_KEYS } from '../../../shared/constants';
import moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/stepper-form.service";
import * as i2 from "ngx-store";
export class ProgressBarService {
    constructor(formService, localStorageService) {
        this.formService = formService;
        this.localStorageService = localStorageService;
        this.barState = new Subject();
        this.max = localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.reservation)
            ? JSON.parse(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.reservation)).expireIn
            : 0;
        this.form = this.formService.getForm();
        this.timer = moment(localStorage.getItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate)).diff(moment(), 'seconds');
        this.progress = (100 * this.timer) / this.max;
    }
    setBar(expireIn, timer) {
        if (!this.interval && (timer || this.max == 0)) {
            if (!this.max) {
                this.max = expireIn;
            }
            timer ? this.timer = timer : this.timer = this.max;
            this.startTimer();
        }
    }
    extendTime(ext) {
        this.max += ext;
        this.timer += ext;
    }
    startTimer() {
        const doThings = () => {
            this.timer >= 0 ? this.timer-- : 0;
            this.form.controls['expireIn'].setValue(this.timer);
            if (this.timer <= 0) {
                this.stopTimer();
            }
            this.progress = (100 * this.timer) / this.max;
            this.barState.next({ progress: this.progress, timer: this.timer });
            if (this.timer <= 0) {
                localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.expirationDate);
                localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.reservation);
                localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.timer);
                localStorage.removeItem(PRENO_LOCAL_STORAGE_KEYS.startedTimer);
            }
        };
        this.interval = setInterval(() => {
            doThings();
        }, 1000);
    }
    stopTimer() {
        this.max = 0;
        this.form.controls['expireIn'].reset();
        this.form.controls['reservationId'].reset();
        if (this.localStorageService.get('stepperData')) {
            this.localStorageService.remove('stepperData');
        }
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = undefined;
    }
    getBarState() {
        return this.barState.asObservable();
    }
}
ProgressBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgressBarService_Factory() { return new ProgressBarService(i0.ɵɵinject(i1.StepperFormService), i0.ɵɵinject(i2.LocalStorageService)); }, token: ProgressBarService, providedIn: "root" });
