import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/ngx-notify/ngx-notify.ngfactory";
import * as i2 from "ngx-notify";
import * as i3 from "angular2-notifications";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common/http";
import * as i6 from "../../node_modules/@nebular/theme/index.ngfactory";
import * as i7 from "@nebular/theme";
import * as i8 from "@angular/router";
import * as i9 from "./app.component";
import * as i10 from "../deploy-url";
import * as i11 from "../base-href";
import * as i12 from "ngx-cookieconsent";
import * as i13 from "@angular/platform-browser";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ngx-notify-notifications", [], null, null, null, i1.View_NgxNotificationsComponent_0, i1.RenderType_NgxNotificationsComponent)), i0.ɵdid(1, 114688, null, 0, i2.NgxNotificationsComponent, [i3.NotificationsService, i4.TranslateService, i5.HttpClient], { options: [0, "options"], i18nJsonPath: [1, "i18nJsonPath"] }, null), i0.ɵpad(2, 2), i0.ɵpod(3, { showProgressBar: 0, timeOut: 1, position: 2, animate: 3, theClass: 4 }), (_l()(), i0.ɵeld(4, 0, null, null, 5, "nb-layout", [], [[2, "window-mode", null], [2, "with-scroll", null], [2, "with-subheader", null]], [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_NbLayoutComponent_0, i6.RenderType_NbLayoutComponent)), i0.ɵdid(5, 4374528, null, 0, i7.NbLayoutComponent, [i7.NbThemeService, i7.NbSpinnerService, i0.ElementRef, i0.Renderer2, i7.NB_WINDOW, i7.NB_DOCUMENT, i0.PLATFORM_ID, i7.NbLayoutDirectionService, i7.NbLayoutScrollService, i7.NbLayoutRulerService, i7.NbRestoreScrollTopHelper, i7.NbOverlayContainerAdapter], null, null), (_l()(), i0.ɵeld(6, 0, null, 3, 3, "nb-layout-column", [["class", "p-0"]], [[2, "left", null], [2, "start", null]], null, null, i6.View_NbLayoutColumnComponent_0, i6.RenderType_NbLayoutColumnComponent)), i0.ɵdid(7, 49152, null, 0, i7.NbLayoutColumnComponent, [], null, null), (_l()(), i0.ɵeld(8, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, 7500, _ck(_v, 2, 0, "bottom", "center"), "fromBottom", "notificationbox"); var currVal_1 = (_co.deployUrl + "assets/i18n"); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 5).windowModeValue; var currVal_3 = i0.ɵnov(_v, 5).withScrollValue; var currVal_4 = i0.ɵnov(_v, 5).withSubheader; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i0.ɵnov(_v, 7).leftValue; var currVal_6 = i0.ɵnov(_v, 7).startValue; _ck(_v, 6, 0, currVal_5, currVal_6); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ngx-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i9.AppComponent, [i5.HttpClient, i10.DEPLOY_URL, i11.BASE_HREF, i12.NgcCookieConsentService, i4.TranslateService, i8.Router, i13.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("ngx-app", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
