<div class="header-container">
  <div class="logo-container">
    <a
      (click)="toggleSidebar()"
      href="#"
      class="sidebar-toggle"
      *ngIf="hideMenu !== true"
    >
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a
      class="logo_img logo"
      href="{{ baseHref }}/public"
      aria-label="logo header"
    >
    </a>
  </div>
</div>
<div class="header-container p-0">
  <nb-actions>
    <nb-action class="user-action" *ngIf="user; else notLogged">
      <nb-user
        role="button"
        aria-label="menù utente (accesso effettuato)"
        [nbContextMenu]="smallDevsMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.firstName + ' ' + user?.lastName"
      >
      </nb-user>
    </nb-action>
    <ng-template #notLogged>
      <nb-user
        class="user-menu"
        role="button"
        aria-label="menù utente (accesso non effettuato)"
        [nbContextMenu]="notLoggedMenu"
        [onlyPicture]="true"
        picture="{{ deployUrl }}assets/images/{{currentTheme}}-theme/user-solid.svg"
      >
      </nb-user>
    </ng-template>
  </nb-actions>
</div>
