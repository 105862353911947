import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {NgxNotificationsService} from "ngx-notify";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private ngxNotificationMessage: NgxNotificationsService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.get('x-jdro-skip-errors') === 'true') {
            return next.handle(request);
        } else {
            return next.handle(request).pipe(
                catchError((err) => {
                    // Il backend ha restituito un codice di risposta di errore.
                    // const modalRef = this.modalService.open(ModalExComponent)
                    /*                    console.log("Request error", err);
                                        this.ngxNotificationMessage.error({body: 'ERRORE'});*/

                    if (err.status === 401 || err.status === 403) {
                        let path = 'auth/login';
                        // console.log("HttpErrorInterceptor redirect to: " + path);
                        this.router.navigate([path]);
                    }
                    return throwError(err);
                }));
        }
    }
}
