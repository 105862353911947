import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../@theme/layouts/one-column/one-column.layout.ngfactory";
import * as i3 from "../../../../../@theme/layouts/one-column/one-column.layout";
import * as i4 from "../../../../../../base-href";
import * as i5 from "../../../../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i6 from "@nebular/theme";
import * as i7 from "@angular/router";
import * as i8 from "./layout.component";
import * as i9 from "../../../services/http/auth.service";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngx-one-column-layout", [], null, null, null, i2.View_OneColumnLayoutComponent_0, i2.RenderType_OneColumnLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i3.OneColumnLayoutComponent, [i4.BASE_HREF], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "nb-menu", [], null, null, null, i5.View_NbMenuComponent_0, i5.RenderType_NbMenuComponent)), i1.ɵdid(3, 4440064, null, 0, i6.NbMenuComponent, [i6.NB_WINDOW, i1.PLATFORM_ID, i6.ɵa, i7.Router], { items: [0, "items"] }, null), (_l()(), i1.ɵeld(4, 16777216, null, 1, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i8.LayoutComponent, [i4.BASE_HREF, i9.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i8.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
