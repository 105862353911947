export const ROLE = {
  Root: 'ROLE_ROOT',
  Admin: 'ROLE_ADMIN',
  Operator: 'ROLE_OPERATORE',
  Download: 'ROLE_DOWNLOAD',
  Scheduler: 'ROLE_SCHEDULER',
} as const;

type ValueOf<T> = T[keyof T];
export type RoleKey = keyof typeof ROLE;
export type Role = ValueOf<typeof ROLE>;
