
        <nb-layout>
            <nb-layout-header fixed>
                <ngx-header [userMenu]="userMenuAdmin"></ngx-header>
            </nb-layout-header>

            <nb-sidebar *ngIf="authenticated" class="menu-sidebar" tag="menu-sidebar" responsive start>
                <ng-content select="nb-menu"></ng-content>
            </nb-sidebar>

            <nb-layout-column>
                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>

            <nb-layout-footer fixed>
                <ngx-footer></ngx-footer>
            </nb-layout-footer>
        </nb-layout>
    